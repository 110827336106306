body {
  background: url('./assets/mur.png');
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

p {
  margin:0
}

  /* the slides */
  .slick-slide {
    margin: 10px 15px;
    outline: none!important
  }
  /* the parent */
  .slick-list {
    margin: 0 -15px;
  }

  .slick-slider {
    height: 125px!important;
    overflow: hidden!important;
  }

  .slick-slide img {
    margin: 0 auto;
    height: 75px;
}

@media screen and (max-width: 600px) {
  .slick-slider {
    height: 135px!important;
  }
}